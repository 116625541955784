'use strict'





routes.home = function(id, action) {
  
  
    mount('loading');
         recipeService.fetchFeaturedRecipes()
        .then(function(recipes) {
          var featuredRecipies={
            mains:{},
            starters:{},
            sides:{},
            combos:{},
            add_ons:{},
            salads:{},
            onam:{}
            
          }
          
             for (var key in recipes ){
               featuredRecipies[recipes[key].category][key]=recipes[key];
             }

             
             
         try {
           for(var cat in global_categories){
       featuredRecipies[global_categories[cat]].sort(function(a,b){
                        return a['featured_order']-b['featured_order'];
                    });


       }
}
catch(err) {
 console.log(err);
}    
             
             
             
             
             
             
             
             
             
          
            mount('home', { recipes: featuredRecipies});
         });

    
}
